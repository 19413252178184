@tailwind base;
@tailwind components;
@tailwind utilities; 

@font-face {
  font-family: 'IRANSans';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts//IRANSans.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IRANSans';
}

* {
  transition: all 0.1s linear;
}

input:disabled {
  color: rgb(40, 40, 40)
}

input::placeholder {
  color: rgb(154, 152, 152)
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-r-nav {
  margin-right: 4rem;
}

@media (max-width: 640px) {
  .m-r-nav {
    margin-right: 0;
  }
}

.driverForm {
  width: 65%;
  margin-right: 2px;
  text-align: right;
  background-color: transparent;
  border-radius: 5px;
  height: 1.5rem;
  padding-inline: .5rem;
  color: black;
}

@media print {
  #printList {
    print-color-adjust: exact; 
  }
}


/* ----- use tooltip and tooltip text */
/* <div class="tooltip">Hover over me
  <span class="tooltipTextLeft">Tooltip text</span>
</div> */
/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltipTextLeft{
  visibility: hidden;
  width: fit-content;
  min-width: 7rem;
  background-color: rgba(0, 0, 0, .6);
  box-shadow: 0px 0px 3px 1px black;
  color: #fff;
  text-align: center;
  padding: 10px 5px;
  border-radius: 6px;
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;
  
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip text */
.tooltip .tooltipTextRight{
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip text */
.tooltip .tooltipTextTop{
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;
  
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip text */
.tooltip .tooltipTextBottom{
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltipTextLeft::after .tooltipTextRight::after .tooltipTextTop::after .tooltipTextBottom::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipTextLeft {
  top: 9px;
  right: 110%;
}

.tooltipTextRight {
  top: 2px;
  left: 230%;
}

.tooltipTextTop {
  bottom: 110%;
  left: 50%;
}

.tooltipTextBottom {
  top: 110%;
  left: 50%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipTextLeft {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltipTextRight {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltipTextTop {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltipTextBottom {
  visibility: visible;
  opacity: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main_page {
  position: fixed;
  z-index: 0;
  background-image: url("./assets/images/tanker.jpg");
  background-color: rgb(26, 33, 52);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

/* @media (max-width: 640px) {
  .main_page {
    background-image: url("./assets/images/tanker-sm.jpg");
  }
} */